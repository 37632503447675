.magazine-container {
    margin-top: 4rem;
}
/* top information on magazine*/

.magazine-image-container,
.magazine-pitch-container {
    margin-top: 0.8rem;
}
.magazine-image-container img{
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
}
.magazine-pitch {
    font-size: 16px;
    font-weight: 200;
    padding: 0
}



.magazine-about-p {
    margin-top: 0.8rem;
    margin-bottom: 0;
}

.contact-price {
    margin-bottom: 0;
}
.contact-shipping {
    font-style: italic;
}
/* the magazines */
.justify-text {
    justify-content: center;
}
.magazine-books-container {
    margin-top: 6rem;
    margin-bottom: 4rem;
}
.magazine-title-container {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.magazine-h2 {
    font-size: 16px;
    font-weight: 400;
    display: inline;
    text-transform: uppercase;
}
.magazine-sm {
    padding-left: 0.4rem;
}

.magazine-one, 
.magazine-two {
    margin-top: 2.5rem;
}

.magazine-soldout,
.magazine-order {
    font-style: italic;
    margin-top: 0.5rem;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 16px;
}

.magazine-comingSoon {
    background-color: #013165a2;
    padding: 0.4rem;
    margin: 0.6rem 0.6rem 0 0.6rem;
}
.magazine-comingSoon p {
    color: white;
    text-align: center;
    margin-bottom: 0;
    font-style: italic;
    cursor: not-allowed;
}
.magazine-order a {
    cursor: pointer;
    color: #013165a2;
    display: block;
    width: 100%;
    text-decoration: none;
}

.magazine-order a:hover {
    color: #013165cf;
    transition: 0.1s ease-in-out;
}


.eachMagazine {
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom:  0.01px solid #e5e7e621;
}


/* ----------- queries -------*/
@media only screen and (min-width: 576px) {
    .magazine-about-p  {
        margin-top: 0;
    } 
}

@media only screen and (min-width: 667px) {
    .magazine-intro-container {
        width: 65vw;
        margin: 0 auto;
        padding-top: 4rem;
    } 
}

@media only screen and (min-width: 767px) { 
    .magazine-image {
        height: 360px;
        overflow: hidden;
    }
    .eachMagazine {
        border-right: 0.01px solid #e5e7e621;;
    }
    .magazine-p {
        margin-top: 0.8rem;
    }
}

@media only screen and (min-width: 992px) {
    .magazine-image {
        height: 250px;
        overflow: hidden;
    }
}

@media only screen and (min-width: 1200px) {
    .magazine-books-container {
        width: 85vw;
        margin: 0 auto;
        padding-top: 4rem;
    }
}



@media only screen and (min-width: 1500px) { 
    .magazine-books-container {
        width: 65vw;
        margin: 0 auto;
        padding-top: 4rem;
    }
}

@media only screen and (min-width: 1600px) {
    .magazine-image {
        height: 300px;
        overflow: hidden;
    }
}