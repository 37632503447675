
.contactHeaderImage {
    background-image: url("../img/banner/contant-intro-image-optimized.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.contact-intro {
    margin-top: 3rem;
}
.contact-intro h1 {
    font-size: 16px;
    font-weight: 400;
    border-bottom: 1px solid #0000003a;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
}

.p-break-contact-form {
    display: block;
    margin-top: 0.7rem;
    font-style: italic;
}

.contact-form {
    padding: 1rem;
    margin-top: 0.7rem;
}
.contact-form input,
.contact-form textarea, 
.contact-form select {
    background-color: #f8f9fa;
    box-shadow: inset 1px 6px 10px 0px #e9e9ee;
}
.contact-form select {
    appearance: none;
}
.contact-form input,
.contact-form textarea,
.contact-form button, 
.contact-form select {
    border: none;
    outline: none;
}

.form-submit-btn{
    background-color: #013165bb;
    color: #fff;
    margin: 1rem 0;
    padding: 1rem 0 ;
}


.input-field:focus,
textarea.input-field:focus {
    outline: 1px solid #00000052;
}

.contact-form .row {
    margin-bottom: 1rem;
}

.label-field {
    padding: 0;
    display: block;
}
.input-field {
    padding: 1rem 0.5rem;
}



.form-error {
    padding: 0;
    color: rgb(78, 14, 14);
    font-weight: 500;
    margin-top: 0.3rem;
}

.form-success-error {
    margin: 2rem 0 4rem;
    font-size: 18px;
    font-style: italic;
    font-weight: 400
}



@media only screen and (min-width: 667px) { 
    .contact-intro,
    .contact-form .container  {
        width: 55vw;
    }
    .contact-intro {
        padding-left: 0;
    }
    .input-field,
    .form-submit-btn {
        padding: 0.5rem;
    }
}