
/* ------------ top content landing page -------------*/
.landingImage {
    background-image: url("../img//banner/landing-intro-image-optimized.jpg"); 
    background-size: cover;
    background-position: bottom;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-position-y: center;
}
.landingPageIntro{
    margin: 3rem 0;
}
.landingPageIntroText {
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    line-height: 1.4;
}
.textSpan {
    display: block;
}

/*--------- images for links homepage ---------*/
.homepageLinkContainer {
 margin: 0 auto;
}
.homepageLinks {
    text-transform: uppercase;
    font-size: 16px;
    color: #fff;
    font-weight: 400;
    text-decoration: none;
}
.aboutUsLink {
    background-image: url("../img/om-oss-optimized.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.previousWorkLink {
    background-image: url("../img/tidligere-arbeid-optimalized.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.pricesLink {
    background-image: url("../img/prislister-optimized.jpg");
    background-size: cover;
    background-position: bottom;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.magazineLink {
    background-image: url("../img/tidsskrift-optimized.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.contactUsLink {
    background-image: url("../img/contact-us-optimized.jpg");
    background-size: cover;
    background-position: bottom;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.housePhotography {
    background-image: url("../img/airBnb/airbnb-38.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}
.weddingLink{
    background-image: url("../img/bryllup-4-optimized-kopi.jpg");
    background-size: cover;
    background-position: center;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 1rem;
}

@media only screen and (min-width: 992px) {
    .landingPageContainer {
        width: 75vw;
        margin: 0 auto;
    }
}