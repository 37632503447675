/*about us section*/
.aboutPage {
    margin-top: 2rem ;
}

.aboutUsP, 
.aboutUsImageContainer {
    margin-top: 0.8rem;
}
.aboutUsImage{
    width: 100%;
    aspect-ratio: 1 / 1;  
    object-fit: cover;
}
.ctaInternal {
    border-bottom: 1px solid #013165a2;
    text-decoration: none;
    color: #013165a2;
    cursor: pointer;
}

/* images of daily life section*/
.articleTwo {
    margin-top: 6rem;
}

.articleTwoTitle {
    font-size: 18px;
    font-weight: 400;
}

.articleTwoSmallP {
    font-size: 16px;
    margin-top: 1.5rem;
}


#photographsDailyWrap {
   flex-wrap: wrap;
   padding: 0 0.5rem;
}
.socialImageWidth {
    width: 100%;
    aspect-ratio: 1 / 1;  
    object-fit: cover;
    padding: 0.3rem;
}

.social-image-container {
    padding: 0;
}

/* social media links section*/
.socialMediaP {
    font-size: 16px;
}

.socialMediaArticle {
    padding: 0 1.5rem 0 1.5rem;
    text-align: center;
}

.socialMediaArticle .container {
    padding: 3rem 6rem;
}

.socialMediaTitle {
    font-size: 20px;
}
.socialMediaContainer {
    margin-top: 1rem;
}
.socialMediaContainer .col-12 {
    padding: 0.5rem;
}
.linkToSoMe {
    text-decoration: none;
    color: #013165a2;
    font-style: italic;
    cursor: pointer;
}

.linkToSoMe:hover{
    color: #1e1e1efa;
    transition: 0.3s ease-in-out;
    
}


@media only screen and (min-width: 667px) {
    .aboutPageImgContainer,
    .aboutPageImageText {
        width: 45vw;
    }
    .aboutPageImgContainer img {
        padding: 0;
    }

    .aboutUs {
        width: 65vw;
        margin: 0 auto;
        padding-top: 4rem;
    }
     
}
@media only screen and (min-width: 992px) {
    .articleTwo .container {
        width: 65vw;
    }
}