/* applies for all top-headers on prices pages*/
.pricesHeader {
    height: 250px;
}
.pricesHeaderTitle {
    position: relative;
    top: 200.6px;
    padding: 0 1.5rem 0 1.5rem;
}
.pricesHeaderTitle h1 {
    margin-bottom: 0;
    color: white;
    font-size: 20px;
    padding: 1rem;
}

/*for prices overwiev page*/
.pricesHeaderImage {
    background-image: url("../img/banner/priser-intro-image-optimized.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

}

/*product container*/
.products {
    padding: 3rem 2.3rem;
}
.product-container .row {
    align-items: center;
}

.product-container .products:nth-child(odd) {
    background-color: #273a3311;
}

/* read more product button*/
.bold {
    font-weight: 400;
}
.cta-products a,
.cta-products {
    color: #013165a2;
    text-decoration: none;
    font-size: 16px;
}
.cta-products a {
    cursor: pointer;
}
.cta-products a:hover {
    color: #013165f5;
    transition: .2s ease-in-out;
}

.product-info h1,  
.product-price h1,
.product-info p, 
.product-price p {
    font-size: 16px;
}

.product-info h1,  
.product-price h1 {
    margin-bottom: 0.2rem;
}
.product-price p {
    font-style: italic;
}

.product-container #two {
    background-color: #273a3325;

}

/* individual prices information page*/
.prices-image-info-container {
    padding: 0.3rem 0.3rem;
}
#prices-information-images-wrap {
    margin-top: 1rem;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.prices-information-page {
    padding: 0 1rem;
}
.prices-info-text {
    margin-top: 4rem;
}
.prices-info-text-title {
    font-weight: 300;
    font-size: 20px;
    margin-bottom: 1rem;
}
.prices-info-text p {
    font-size: 16px;
}

@media only screen and (min-width: 576px) {
    .product-price p {
        text-align: center;
    }
}

@media only screen and (min-width: 990px) {
    .prices-information-page {
        width: 65vw;
    }
}