.navbar {
    background-color: #fff;
    padding: 0.8rem 1rem;
    position: fixed;
    width: 100vw;
    z-index: 1;
    top: 0;
}
.navbar .row {
    width: 100vw;
    align-items: center;
}
.navbar.active{
    opacity: 0;
    transition: 0.4s ease-in-out;
}

.brandIcon, .globalNav a {
    color: #262626ea;
    cursor: pointer;
    list-style: none;
    text-decoration: none;
    margin-bottom: 0;
}
.brandIcon {
    font-size: 18px;
}

.mobileNav a {
    padding-top: 1rem;
    display: block;
    font-size: 16px;
}
.mobileNav {
    padding-bottom: 1rem;
}
.burgerMenu {
    text-align: end;
    position: relative;
}
.burgerMenu button {
    background: none;
    border: none;
    color: #262626ea;
}

.desktopNav{
    display: inline;
    text-align: end;
}
.desktopNav a{
    margin-right: 1rem;
}
.navDropdownDivider {
    border-top: 1px solid black;
    opacity: 2%;
    margin-bottom: 0.4rem;
}

.dropDownNav {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: #fff;
    margin: 0;
    max-height: 0;
    transform: translateY(-10px);
    opacity: 0;
    transition: max-height 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in;
}

.dropDownNav.open {
    max-height: 300px;
    transform: translateY(0);
    opacity: 1;
}
@media only screen and (min-width: 771px) {
    .globalNav a {
        margin-right: 1rem;
        display: inline;
    }
    .navbar {
        padding: 1rem;
    }
    .navDropdownDivider {
        margin-bottom: 1rem;
    }
}