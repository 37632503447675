.portfolioHeaderImage {
    background-image: url("../img/banner/tidligere-arbeid-intro-image-optimized.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.portfolio-container .row{
    margin-top: 4rem;
}
.portfolio-cta-container {
  margin: 0.5rem 0;
}
.portfolio-cta-container{
    text-decoration: none;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.ambijus {
    background-image: url(../img/portfolio/ambijus/ambijus-2-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.newMovements {
    background-image: url(../img/portfolio/new-movements/nm-13-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    
}

.cliffCabin {
    background-image: url(../img/portfolio/cliff-cabin/cliff-cabin-3-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.winebarMolla {
    background-image: url(../img/portfolio/vinbaren/vinbaren-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.kok {
    background-image: url(../img/portfolio/kok/kok-optimized.jpg);
    background-size: cover;
    background-position: bottom;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.torungenDesign {
    background-image: url(../img/portfolio/torungen/torungen-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.wilderKitchen {
    background-image: url(../img/portfolio/wilder-kitchen/wk-1-optimized.jpg);
    background-size: cover;
    background-position: bottom;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.bergill {
    background-image: url(../img/portfolio/bergill/bergill2-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.sovesveis{
    background-image: url(../img/portfolio/sovesveis/sovesveis-2-optimized.jpg);
    background-size: cover;
    background-position: center;
    height: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}